import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import useAxios from "../../hooks/useAxios";
import { useDispatch } from "react-redux";
import { setTab } from "../redux/slice/PositionSlice";

function Transaction() {
  const gridRef = useRef(null);
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const [userId, setUserId] = useState();
  const [totalPNL, setTotalPNL] = useState(0);
  const getTransaction = useAxios("GET", `/finance/summary?user_id=${userId}`);
  const getUser = useAxios("GET", "/finance/get_user");
  const BtnCellRenderer = (value) => {
    return (
      <button
        onClick={() => {
          console.log(value.data["Transaction type"]);
          dispatch(
            setTab({
              activeTab: "myData",
              params: { transaction_type: value.data["Transaction type"] },
            })
          );
        }}
        className="text-white bg-[#0083F2] rounded-lg p-[10px] leading-[12px]  me-10"
      >
        Show All
      </button>
    );
  };
  useEffect(() => {
    getUser.fetchData();
  }, []);
  useEffect(() => {
    if (getUser.responseData) {
      setUserId(getUser.responseData.email);
    }
  }, [getUser.responseData]);
  useEffect(() => {
    if (getUser.responseData?.email) {
      getTransaction.fetchData();
    }
  }, [userId]);
  useEffect(() => {
    if (getTransaction.responseData) {
      console.log("TRANSACTIONS ", getTransaction.responseData);
      let tempPNL = 0;
      const mappedData = getTransaction.responseData.map((item) => {
        tempPNL += parseFloat(item.total_amount || 0);
        return {
          "Transaction type": item.transaction_type,
          Amount: new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: getUser.responseData.portfolio_ccy,
          }).format(item.total_amount),
          Menu: "Show All",
        };
      });
      setTotalPNL(tempPNL);
      setRowData(mappedData);
    }
  }, [getTransaction.responseData]);
  const onGridReady = (params) => {
    gridRef.current = params.api;
    params.api.showLoadingOverlay(); // Show the loading overlay
  };
  const [colDefs] = useState([
    {
      headerName: "Transaction type",
      field: "Transaction type",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Amount",
      field: "Amount",
      unSortIcon: true,
      cellStyle: { display: "flex", justifyContent: "center" },
      headerClass: "header-third",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Menu",
      field: "Menu",
      unSortIcon: true,
      cellRenderer: BtnCellRenderer,
      cellStyle: { display: "flex", justifyContent: "flex-end" },
      headerClass: "header-last",
      filter: "agTextColumnFilter",
    },
  ]);

  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitGridWidth",
    };
  }, []);
  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
    };
  }, []);
  const getRowHeight = () => {
    return 50;
  };

  return (
    <>
      <div className="bg-[#161D27] rounded-lg flex justify-between p-[15px] items-center mb-5">
        <h3 className="text-white text-xs leading-[18px]">
          Total Profit & Loss
        </h3>
        <h2
          className={`text-[28px] ${
            totalPNL >= 0 ? "text-green-500" : "text-red-500"
          } font-semibold leading-[28px]`}
        >
          {getUser.responseData
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: getUser.responseData?.portfolio_ccy,
              }).format(totalPNL)
            : ""}
        </h2>
      </div>

      <div className="flatposition transition-tab">
        <div
          className="ag-theme-alpine ag-theme-quartz"
          style={{ height: "75vh", width: "100%" }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            autoSizeColumns={true}
            autoSizeStrategy={autoSizeStrategy}
            ref={gridRef}
            defaultColDef={defaultColDef}
            getRowHeight={getRowHeight}
            onGridReady={onGridReady}
          />
        </div>
      </div>
    </>
  );
}

export default Transaction;
