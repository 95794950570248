import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import useAxios from '../../hooks/useAxios';


function FlatPosition() {
    const gridRef = useRef(null);
  const [rowData, setRowData] = useState([])
  const [totalPNL, setTotalPNL] = useState(0)
  const getFlatPosition = useAxios("GET", "/finance/flat_positions");
  const getUser = useAxios("GET", "/finance/get_user");
  useEffect(() => {
    getFlatPosition.fetchData()
    getUser.fetchData()
  }, []);

  const linkCellRendererSN= (data) => {
    const reqData=JSON.parse(data.data["Security Name"])
    return (
      <a className={reqData.security_urls[0]?.security_name_url?"text-blue-500":""} href={reqData.security_urls[0]?.security_name_url} target="blank">
        {reqData.security.name}
      </a>
    );
  };

  const linkCellRendererST= (data) => {
    const reqData=JSON.parse(data.data["Security Ticker"])
    return (
      <a className={reqData.security_urls[0]?.security_ticker_url?"text-blue-500":""} href={reqData.security_urls[0]?.security_ticker_url} target="blank">
        {reqData.security.ticker}
      </a>
    );
  };

  const linkCellRendererISIN= (data) => {
    const reqData=JSON.parse(data.data["Security ISIN"])
    return (
      <a className={reqData.security_urls[0]?.security_isin_url?"text-blue-500":""} href={reqData.security_urls[0]?.security_isin_url} target="blank">
        {reqData.security.isin}
      </a>
    );
  };
  useEffect(() => {
    console.log("Response data",getFlatPosition.responseData)
    let tempPNL = 0;
    if(getFlatPosition.responseData && getUser.responseData){
      const mappedData = getFlatPosition.responseData.map((item)=>{
        tempPNL += parseFloat(item.realized_pnl || 0);
        return {
          'Security Name': JSON.stringify(item),
          'Security Ticker': JSON.stringify(item),
          'Security ISIN': JSON.stringify(item), 
          'Security Currency': item.security.currency, 
          'Realized P & L': new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: getUser.responseData.portfolio_ccy,
          }).format(item.realized_pnl),
        }
      })
      setRowData(mappedData)
      setTotalPNL(tempPNL)
    }
  }, [getFlatPosition.responseData,getUser.responseData])
  
  const [colDefs] = useState([
    { 
      headerName: 'Security Name',
      field: 'Security Name',
      unSortIcon: true,
      filter: 'agTextColumnFilter',
      cellRenderer: linkCellRendererSN,
    },
    { 
      headerName: 'Security Ticker',
      field: 'Security Ticker',
      unSortIcon: true ,
      filter: 'agTextColumnFilter', 
      cellRenderer: linkCellRendererST,
    },
    { 
      headerName: 'Security ISIN',
      field: 'Security ISIN',
      unSortIcon: true,
      filter: 'agTextColumnFilter',
      cellRenderer: linkCellRendererISIN,
    },
    { headerName: 'Security Currency', field: 'Security Currency',unSortIcon: true,filter: 'agTextColumnFilter', },
    { headerName: 'Realized P & L', field: 'Realized P & L',unSortIcon: true ,},
  ]); 

  const autoSizeStrategy = useMemo(() => {
    return {
      type: 'fitGridWidth',
    }
  }, []);
  const defaultColDef = useMemo(() => {
    return {
      filter: 'agTextColumnFilter', 
      menuTabs: ['filterMenuTab'],
    };
  }, []);
  const onGridReady = (params) => {
    gridRef.current = params.api;
    params.api.showLoadingOverlay(); // Show the loading overlay
  };
  return (
    <>
           <div className="bg-[#161D27] rounded-lg flex justify-between p-[15px] items-center mb-5">
        <h3 className='text-white text-xs leading-[18px]'>Total Profit & Loss</h3>
        <h2
          className={`text-[28px] ${
            totalPNL >= 0 ? "text-green-500" : "text-red-500"
          } font-semibold leading-[28px]`}
        >
          {getUser.responseData
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: getUser.responseData?.portfolio_ccy,
              }).format(totalPNL)
            : ""}
       
        </h2>
        </div>
        <div className="flatposition">
        <div className="ag-theme-alpine ag-theme-quartz" style={{ height: '75vh', width: '100%' }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          autoSizeStrategy={autoSizeStrategy}
          defaultColDef={defaultColDef}
          ref={gridRef}
          onGridReady={onGridReady}
        />
      </div>
        </div>
    </>
  )
}

export default FlatPosition
