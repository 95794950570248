import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import useAxios from "../../hooks/useAxios";

function Dividends() {
  const [selectedSecurity, setSelectedSecurity] = useState("");
  const getNetSecurities = useAxios("GET", "/finance/get-net-securities/");
  const getDividends = useAxios(
    "GET",
    `/finance/get-dividends/?security=${selectedSecurity}`
  );
  const [dividend, setDividend] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleDividends = () => {
    getDividends.fetchData();
    setDividend(!dividend);
  };

  const handleSecurityChange = (event) => {
    setSelectedSecurity(event.target.value);
  };
  useEffect(() => {
    const getData = async () => {
      if (dividend) {
        setLoading(true);
        await getDividends.fetchData();
        setLoading(false);
      }
    };
    getData();
  }, [selectedSecurity]);

  const gridRef = useRef(null);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    getNetSecurities.fetchData();
  }, []);

  const [colDefs] = useState([
    {
      headerName: "Dividend Date",
      field: "dividendDate",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Stock",
      field: "stock",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Dividend Amount",
      field: "dividendAmount",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Transaction Date",
      field: "yourPosition",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Transaction",
      field: "transaction",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "No. of shares",
      field: "no_of_shares",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "NET Position",
      field: "netPosition",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Received Dividend Amount",
      field: "dividendAmount2",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
  ]);

  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitGridWidth",
    };
  }, []);
  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
    };
  }, []);
  return (
    <>
      {dividend ? (
        <>
          <div className="flex justify-between items-center mb-5">
            <h3 className="text-lg leading-4 text-white">Dividends</h3>
            <select
              className="border border-[#3E3F53] bg-[#131720] p-2 w-60 rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white outline-0"
              onChange={handleSecurityChange}
              value={selectedSecurity}
            >
              {getNetSecurities.responseData?.data.map((value, index) => (
                <option key={index + value + "b"} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
            <div>
              
            {loading ? <h1 className="text-white mt-2 mb-3 ">Loading.....</h1> : ""}
            </div>
          <div className="flatposition">
            <div
              className="ag-theme-alpine"
              style={{ height: "75vh", width: "100%" }}
            >
              <AgGridReact
                rowData={getDividends.responseData?.data?.map((item) => {
                  return {
                    ...item,
                    dividendAmount2: parseFloat(item.dividendAmount2).toFixed(
                      2
                    ),
                    no_of_shares:item.no_of_shares.toString()
                  };
                })}
                columnDefs={colDefs}
                autoSizeColumns={true}
                autoSizeStrategy={autoSizeStrategy}
                ref={gridRef}
                defaultColDef={defaultColDef}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="bg-[#161D27] p-4 rounded-bl-lg rounded-br-lg">
            <h3 className="text-white text-lg mb-3">Dividends</h3>
            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 lg:col-span-5">
                <div className="pb-3">
                  <h3 className="text-xs text-white">Select security :</h3>
                </div>
                <select
                  className="w-full border border-[#3E3F53] bg-[#131720] p-3 rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white outline-0"
                  onChange={handleSecurityChange}
                  value={selectedSecurity}
                >
                  <option value="VOD.LSE">Select</option>
                  {getNetSecurities.responseData?.data.map((value, index) => (
                    <option key={index + value + "b"} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="text-center mt-5 mb-5">
            <button
              className="bg-[#0083F2] text-white py-[14px] px-[40px] rounded-md"
              onClick={handleDividends}
            >
              Get Dividends
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default Dividends;
