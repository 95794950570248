
import { useState } from 'react';
import api from '../api/axiosInstance';
import { useAuth0 } from '@auth0/auth0-react';

const useAxios = (method, url) => {
  const [responseData, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently,isLoading } = useAuth0();
  const fetchData = async (params = {}, customHeaders = {}, requestData = null) => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently({
      audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
      scope: "read:current_user",
  });

      const response = await api({
        method,
        url,
        data: requestData,
        params,
        headers: {
          ...customHeaders,
          'Authorization': `Bearer ${accessToken}`,
        },
      });
      setData(response.data);
      setLoading(false);
    
  };

  return { responseData, error, loading, fetchData };
};

export default useAxios;
