import React, { useState, useEffect } from "react";
import { IoSearch } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useAxios from "../../hooks/useAxios";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
const CustomDatePicker = ({ selectedDate, onDateChange }) => {
  return (
    <>
      <div className="relative">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => onDateChange(date)}
          className="relativedata border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
        />
        <i className="my-icon absolute top-3 right-4"></i>
      </div>
    </>
  );
};

function AddPosition() {
  const { user } = useAuth0();
  const [showAllColumns, setShowAllColumns] = useState(true);
  const [transactionTypes, setTransactionTypes] = useState();
  const [startDate1, setStartDate1] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [isChecked, setChecked] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState();
  const [securitName, setSecuritName] = useState("");
  const [securitCCY, setSecuritCCY] = useState("");
  const [showForeignCCY, setShowForeignCCY] = useState(false);
  const [btnText, setBtnText] = useState("Add Position")
  const addPosition=useAxios("POST","/finance/positions/")
  const [selectedTransactionType, setSelectedTransactionType] = useState("Buy");
  const [formData, setFormData] = useState({
    broker_reference: "",
    brokerage_foreign_ccy: 0,
    brokerage_local_ccy: 0,
    foreign_ccy: 0,
    fx_rate: 0,
    fx_transaction_cost_foreign_ccy: 0,
    fx_transaction_cost_local_ccy: 0,
    local_ccy: "",
    quantity: 0,
    security: "",
    settlement_date: "",
    total_consideration_foreign_ccy: 0,
    total_consideration_local_ccy: 0,
    trade_date: "",
    transaction_description: "",
    transaction_quantity: 0,
    transaction_tax_foreign_ccy: 0,
    transaction_tax_local_ccy: 0,
    transaction_type: "Buy",
    trim_code: "",
    unit_cost_foreign_ccy: 0,
    unit_cost_local_ccy: 0,
    user: "",
  });

  const responseTransactionType = useAxios(
    "GET",
    "/finance/transaction_types/"
  );

  const responseUserData = useAxios("GET", "/finance/get_user");

  useEffect(() => {
    responseTransactionType.fetchData();
    responseUserData.fetchData();
  }, []);

  useEffect(() => {
    let transactionTypeTemp = [];
    responseTransactionType.responseData?.results.forEach(
      (value, index, array) => {
        transactionTypeTemp.push(value.transaction_type);
      }
    );
    setTransactionTypes(transactionTypeTemp);
  }, [responseTransactionType.responseData]);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      local_ccy: responseUserData?.responseData?.portfolio_ccy,
    }));
    console.log(responseUserData.responseData);
  }, [responseUserData.responseData]);

  useEffect(() => {
    const getFilteredData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/finance/get_securities`,
        {
          params: {
            q: searchQuery,
          },
        }
      );
      setFilteredData(response.data);
    };
    if (searchQuery) {
      getFilteredData();
    }
  }, [searchQuery]);
  // Function to handle changes to any input
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const isNumber = event.target.type === "number";
    console.log("Name : ",name)
    setFormData((prevState) => ({
      ...prevState,
      [name]: isNumber ? Number(value) : value,
    }));
  };

  useEffect(() => {
    if(selectedTransactionType==="Buy"){
      setFormData((prevState) => ({
        ...prevState,
        "total_consideration_local_ccy":-((parseInt(formData.quantity)*parseFloat(formData.unit_cost_local_ccy))+(parseInt(formData.brokerage_local_ccy)||0)+(parseInt(formData.transaction_tax_local_ccy)||0)+(parseInt(formData.fx_transaction_cost_local_ccy)||0)),
      }));
    }else{
      setFormData((prevState) => ({
        ...prevState,
        "total_consideration_local_ccy":((parseInt(formData.quantity)*parseFloat(formData.unit_cost_local_ccy))+(parseInt(formData.brokerage_local_ccy)||0)+(parseInt(formData.transaction_tax_local_ccy)||0)+(parseInt(formData.fx_transaction_cost_local_ccy)||0)),
      }));
    }
  }, [formData.quantity,formData.unit_cost_local_ccy,formData.brokerage_local_ccy,formData.transaction_tax_local_ccy,formData.fx_transaction_cost_local_ccy,formData.transaction_type,selectedTransactionType])
  
  const submitAddPositionForm = async () => {
    console.log(responseUserData.responseData);
    console.log(formData);
    setBtnText("Adding.....")
    await addPosition.fetchData({},{},{
      ...formData,
      trade_date:`${String(startDate1.getUTCDate()).padStart(2, '0')}/${String(startDate1.getUTCMonth() + 1).padStart(2, '0')}/${startDate1.getUTCFullYear()}`,
      settlement_date:`${String(startDate2.getUTCDate()).padStart(2, '0')}/${String(startDate2.getUTCMonth() + 1).padStart(2, '0')}/${startDate2.getUTCFullYear()}`,
      total_consideration_local_ccy:formData.total_consideration_local_ccy.toString(),
      unit_cost_local_ccy:parseFloat(formData.unit_cost_local_ccy),
      user:user.email,
      total_consideration_local_ccy:parseFloat(formData.total_consideration_local_ccy),
      transaction_quantity:formData.quantity
    })
    setBtnText("Add Positon")
    alert("Position Added Successfully !");
  };
  const handleDateChange1 = (date) => {
    setStartDate1(date);
  };

  const handleDateChange2 = (date) => {
    setStartDate2(date);
  };
  const handleCheckboxChange = () => {
    setShowAllColumns(!showAllColumns);
    setChecked(!isChecked);
  };



  const handleTransactionTypeChange = (event) => {
    setSelectedTransactionType(event.target.value);
  };
  const selectSearchedTicker = (item) => {
    console.log(item);
    setFormData((prevState) => ({
      ...prevState,
      security: item.ticker,
    }));
    setSecuritCCY(item.currency);
    setSecuritName(item.name);
    if (formData.local_ccy!==item.currency) {
      setShowForeignCCY(true)
    }else{
      setShowForeignCCY(false)
    }
    
    setSearchQuery("");
  };
  return (
    <>
      <div className="pb-[15px]">
        <h2 className="text-white text-md leading-[18px] mb-3 font-medium">
          Search Ticker
        </h2>
        <div className="relative">
          <input
            type="search"
            className="bg-[#161D27] rounded-lg w-full py-[11px] outline-none px-14 placeholder:text-sm placeholder:text-[#3E3F53] text-white focus:border focus:border-[#0083F2] focus:border-opacity-50"
            placeholder="Type to load suggestions - Enter security name, ticker or isin to search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <IoSearch className="absolute text-white top-3 left-4 text-xl " />
        </div>
      </div>
      {searchQuery !== "" ? (
        <ul>
          {filteredData?.map((item, index) => (
            <li
              key={index}
              className='py-[10px] px-[15px] bg-[#132332] rounded-lg mb-[10px] transition-all duration-[0] relative hover:after:absolute after:bg-[#0083F2] after:top-0 after:left-0 after:content-[""] after:w-[6px] after:h-full after:rounded-tr-lg after:rounded-br-lg'
              onClick={(e) => selectSearchedTicker(item)}
            >
              <h3 className="text-white text-xs leading-[18px] mb-1">
                {item.name} | {item.ticker} | {item.isin}
              </h3>
              <p className="text-xs text-white text-opacity-50 leading-[18px]">
                Country: {item.country} | Exchange: {item.exchange} | ISIN:{" "}
                {item.isin} | Type: {item.type}
              </p>
            </li>
          ))}
        </ul>
      ) : (
        <>
          {selectedTransactionType === "Accounting Transfer" ? (
            <>
              <div className="bg-[#161D27] rounded-lg p-[15px]">
                <div className="grid grid-cols-12 gap-5 pb-6">
                  <div className="sm:col-span-4 col-span-6">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Transaction type</h3>
                    </div>
                    <select
                      className="w-full border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white outline-0"
                      onChange={handleTransactionTypeChange}
                      value={selectedTransactionType}
                    >
                      {transactionTypes?.map((value, idx) => {
                        return (
                          <option value={value} key={idx}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="sm:col-span-4 col-span-6">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Trade Date</h3>
                    </div>
                    <CustomDatePicker
                      selectedDate={startDate1}
                      onDateChange={handleDateChange1}
                    />
                  </div>
                  <div className="sm:col-span-4 col-span-6">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Settlement Date</h3>
                    </div>
                    <CustomDatePicker
                      selectedDate={startDate2}
                      onDateChange={handleDateChange2}
                    />
                  </div>
                  <div className="sm:col-span-4 col-span-6">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Broker reference</h3>
                    </div>
                    <input
                      type="text"
                      className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                      name="broker_reference"
                      value={formData.broker_reference}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="sm:col-span-4 col-span-12">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">
                        Transaction description
                      </h3>
                    </div>
                    <input
                      type="text"
                      placeholder="Type to search security ticker"
                      className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                      name="transaction_description"
                      value={formData.transaction_description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="sm:col-span-4 col-span-12">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">
                        Total consideration local ccy
                      </h3>
                    </div>
                    <input
                      type="number"
                      placeholder="Type to search security ticker"
                      className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                      name="total_consideration_local_ccy"
                      value={formData.total_consideration_local_ccy}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="bg-[#161D27] rounded-lg p-[15px]">
                <div className="flex w-full gap-5 pb-5 items-center flex-wrap xl:flex-nowrap">
                  <div className="xl:w-[603px] lg:w-[552px] md:[552px] w-[496px]">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Transaction type</h3>
                    </div>
                    <select
                      className="w-full border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white outline-0"
                      onChange={handleTransactionTypeChange}
                      value={selectedTransactionType}
                    >
                      {transactionTypes?.map((value, idx) => {
                        return (
                          <option value={value} key={idx + value}>
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="xxl:w-[150px] xl:w-[189px] sm:w-[184px] w-[167px]">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Trade Date</h3>
                    </div>
                    <CustomDatePicker
                      selectedDate={startDate1}
                      onDateChange={handleDateChange1}
                    />
                  </div>
                  <div className="xxl:w-[150px] xl:w-[189px] sm:w-[184px] w-[167px]">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Settlement Date</h3>
                    </div>
                    <CustomDatePicker
                      selectedDate={startDate2}
                      onDateChange={handleDateChange2}
                    />
                  </div>
                  <div className="lg:w-[447.39px] sm:w-[260.39px] w-[210.39px]">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Security ticker</h3>
                    </div>
                    <input
                      type="text"
                      placeholder="Type to search security ticker"
                      className="border border-[#3E3F53] bg-[#132332] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                      readOnly
                      value={formData.security}
                    />
                  </div>
                  <div className="xxl:w-[364px] xl:w-[341px] lg:w-[378px] sm:w-[218px] w-[128px]">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Quantity</h3>
                    </div>
                    <input
                      type="text"
                      className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                      name="quantity"
                      value={formData.quantity}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="addposition grid grid-cols-12 gap-5">
                  <div className="lg:col-span-4 col-span-8">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Security name</h3>
                    </div>
                    <input
                      type="text"
                      className="border border-[#3E3F53] bg-[#132332] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                      readOnly
                      value={securitName}
                    />
                  </div>
                  <div className="2xl:col-span-1 lg:col-span-2 sm:col-span-3 col-span-9">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Security currency</h3>
                    </div>
                    <input
                      type="text"
                      className="border border-[#3E3F53] bg-[#132332] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                      readOnly
                      value={securitCCY}
                    />
                  </div>
                  <div className="lg:col-span-3 col-span-5">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">Broker reference</h3>
                    </div>
                    <input
                      type="text"
                      className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                      name="broker_reference"
                      value={formData.broker_reference}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="2xl:col-span-4 lg:col-span-3 col-span-6">
                    <div className="pb-3">
                      <h3 className="text-xs text-white">
                        Transaction description
                      </h3>
                    </div>
                    <input
                      type="text"
                      className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                      name="transaction_description"
                      value={formData.transaction_description}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="py-[15px]  flex justify-between items-center ">
                <h2 className='text-lg text-white relative after:absolute after:bg-[#0083F2] after:top-0 after:left-0 after:content-[""] after:w-[6px] after:h-full px-4 after:rounded-tr-lg after:rounded-br-lg'>
                  Local CCY
                </h2>
                <label className="text-sm relative inline-flex items-center cursor-pointer text-[#3E3F53] gapy-[11px] px-[15px] gap-3">
                  Simple Cost
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer ps-4"
                    onChange={handleCheckboxChange}
                  />
                  <div
                    className={`w-12 h-7 bg-[#132332] rounded-full  peer ${
                      isChecked ? "peer-checked" : ""
                    }`}
                  >
                    <img
                      className={`rounded-full  w-6 h-6 mt-[2px] ${
                        isChecked ? "-translate-x-0" : "translate-x-full"
                      }`}
                      src={
                        isChecked ? "images/uncheck.png" : "images/check.png"
                      }
                      alt="Check"
                    />
                  </div>
                </label>
              </div>
              <div className="bg-[#161D27] rounded-lg py-[15px] ps-[15px] pe-[25px]">
                <div className="grid grid-cols-12 gap-5 ">
                  {showAllColumns ? (
                    <>
                      <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                        <div className="pb-3">
                          <h3 className="text-xs text-white">Local ccy</h3>
                        </div>
                        <input
                          type="text"
                          readOnly
                          className="border border-[#3E3F53] bg-[#132332] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                          value={formData.local_ccy}
                        />
                      </div>
                      <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                        <div className="pb-3">
                          <h3 className="text-xs text-white">Unit cost</h3>
                        </div>
                        <input
                          type="text"
                          className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                          name="unit_cost_local_ccy"
                          value={formData.unit_cost_local_ccy}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                        <div className="pb-3">
                          <h3 className="text-xs text-white">
                            Total consideration
                          </h3>
                        </div>
                        <input
                          type="text"
                          className="border border-[#3E3F53] bg-[#132332] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                          name="total_consideration_local_ccy"
                          value={formData.total_consideration_local_ccy}
                          onChange={handleInputChange}
                          readOnly
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                        <div className="pb-3">
                          <h3 className="text-xs text-white">Local ccy</h3>
                        </div>
                        <input
                          type="text"
                          readOnly
                          className="border border-[#3E3F53] bg-[#132332] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                          value={formData.local_ccy}
                        />
                      </div>
                      <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                        <div className="pb-3">
                          <h3 className="text-xs text-white">Unit cost</h3>
                        </div>
                        <input
                          type="text"
                          className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                          name="unit_cost_local_ccy"
                          value={formData.unit_cost_local_ccy}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                        <div className="pb-3">
                          <h3 className="text-xs text-white">Brokerage</h3>
                        </div>
                        <input
                          type="text"
                          className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                          name="brokerage_local_ccy"
                          value={formData.brokerage_local_ccy}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                        <div className="pb-3">
                          <h3 className="text-xs text-white">
                            Transaction tax{" "}
                          </h3>
                        </div>
                        <input
                          type="text"
                          className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                          name="transaction_tax_local_ccy"
                          value={formData.transaction_tax_local_ccy}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                        <div className="pb-3">
                          <h3 className="text-xs text-white">
                            Fx transaction cost{" "}
                          </h3>
                        </div>
                        <input
                          type="text"
                          className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                          name="fx_transaction_cost_local_ccy"
                          value={formData.fx_transaction_cost_local_ccy}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                        <div className="pb-3">
                          <h3 className="text-xs text-white">
                            Total consideration
                          </h3>
                        </div>
                        <input
                          type="text"
                          className="border border-[#3E3F53] bg-[#132332] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                          name="total_consideration_local_ccy"
                          value={formData.total_consideration_local_ccy}
                          onChange={handleInputChange}
                          readOnly
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {showForeignCCY ? (
                <>
                  <div className="py-[15px] flex justify-between items-center">
                    <h2 className='text-lg text-white relative after:absolute after:bg-[#0083F2] after:top-0 after:left-0 after:content-[""] after:w-[6px] after:h-full px-4 after:rounded-tr-lg after:rounded-br-lg'>
                      Foreign CCY
                    </h2>
                  </div>
                  <div className="bg-[#161D27] rounded-lg py-[15px] ps-[15px] pe-[25px]">
                    <div className="grid grid-cols-12 gap-5">
                      {showAllColumns ? (
                        <>
                          <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                            <div className="pb-3">
                              <h3 className="text-xs text-white">
                                Foreign ccy
                              </h3>
                            </div>
                            <input
                              type="text"
                              readOnly
                              className="border border-[#3E3F53] bg-[#132332] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                              value={securitCCY}
                            />
                          </div>
                          <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                            <div className="pb-3">
                              <h3 className="text-xs text-white">Unit cost</h3>
                            </div>
                            <input
                              type="text"
                              className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                              name="unit_cost_foreign_ccy"
                              value={formData.unit_cost_foreign_ccy}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                            <div className="pb-3">
                              <h3 className="text-xs text-white">
                                Total consideration
                              </h3>
                            </div>
                            <input
                              type="text"
                              className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                              name="total_consideration_foreign_ccy"
                              value={formData.total_consideration_foreign_ccy}
                              onChange={handleInputChange}
                              readOnly
                            />
                          </div>
                          <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                            <div className="pb-3">
                              <h3 className="text-xs text-white">Fx rate</h3>
                            </div>
                            <input
                              type="text"
                              className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                              name="fx_rate"
                              value={formData.fx_rate}
                              onChange={handleInputChange}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                            <div className="pb-3">
                              <h3 className="text-xs text-white">
                                Foreign ccy
                              </h3>
                            </div>
                            <input
                              type="text"
                              readOnly
                              className="border border-[#3E3F53] bg-[#132332] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                              value={securitCCY}

                            />
                          </div>
                          <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                            <div className="pb-3">
                              <h3 className="text-xs text-white">Unit cost</h3>
                            </div>
                            <input
                              type="text"
                              className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                              name="unit_cost_foreign_ccy"
                              value={formData.unit_cost_foreign_ccy}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                            <div className="pb-3">
                              <h3 className="text-xs text-white">Brokerage</h3>
                            </div>
                            <input
                              type="text"
                              className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                              name="brokerage_foreign_ccy"
                              value={formData.brokerage_foreign_ccy}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                            <div className="pb-3">
                              <h3 className="text-xs text-white">
                                Transaction tax{" "}
                              </h3>
                            </div>
                            <input
                              type="text"
                              className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                              name="transaction_tax_foreign_ccy"
                              value={formData.transaction_tax_foreign_ccy}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                            <div className="pb-3">
                              <h3 className="text-xs text-white">
                                Fx transaction cost{" "}
                              </h3>
                            </div>
                            <input
                              type="text"
                              className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                              name="fx_transaction_cost_foreign_ccy"
                              value={formData.fx_transaction_cost_foreign_ccy}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                            <div className="pb-3">
                              <h3 className="text-xs text-white">
                                Total consideration
                              </h3>
                            </div>
                            <input
                              type="text"
                              className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                              name="total_consideration_foreign_ccy"
                              value={formData.total_consideration_foreign_ccy}
                              onChange={handleInputChange}
                              readOnly
                            />
                          </div>
                          <div className="xl:col-span-2 lg:col-span-3 sm:col-span-3 col-span-6">
                            <div className="pb-3">
                              <h3 className="text-xs text-white">Fx rate</h3>
                            </div>
                            <input
                              type="text"
                              className="border border-[#3E3F53] bg-[#131720] py-[11px] px-[15px] rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white w-full"
                              name="fx_rate"
                              value={formData.fx_rate}
                              onChange={handleInputChange}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </>
          )}
          <div className="text-center mt-5">
            <button
              onClick={submitAddPositionForm}
              className="bg-[#0083F2] text-white py-[14px] px-[40px] rounded-md"
            >
             {btnText}
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default AddPosition;
