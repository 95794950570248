

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import HomePage from './component/home/HomePage';
import Navbar from './component/navbar/Navbar';
import Profile from './component/profile/Profile';


function App() {
  const { isAuthenticated, loginWithRedirect, logout, isLoading } = useAuth0();
  if (isLoading) {
    return <span>Loading....</span>; // Show a loading spinner or a placeholder
  }
  return (
    <>
      <BrowserRouter>
        <Navbar onLogin={() => loginWithRedirect()} onLogout={() => logout({ logoutParams: { returnTo: window.location.origin } })} />
     
        <Routes>
          <Route path="/" element={isAuthenticated ? <HomePage /> : loginWithRedirect()} />
          <Route path="/profile" element={isAuthenticated ? <Profile /> : loginWithRedirect()} />
          {/* Add more protected routes as needed */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
