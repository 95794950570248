import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { FaCircleCheck } from "react-icons/fa6";
import useAxios from "../../hooks/useAxios";
import { useCallback } from "react";
import { forwardRef, useImperativeHandle } from "react";
const KEY_BACKSPACE = "Backspace";
const KEY_DELETE = "Delete";
const KEY_F2 = "F2";
const KEY_ENTER = "Enter";
const KEY_TAB = "Tab";

// Custom Cell Editor Component
const CustomCellEditor = forwardRef((props, ref) => {
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getValue() {
      return inputRef.current.value;
    },
    afterGuiAttached() {
      inputRef.current.focus();
    },
  }));

  const handleInputChange = (event) => {
    console.log(props.data["Holding"]);
    const holding =
      parseInt(props.data["Holding"]) < 0
        ? -parseInt(props.data["Holding"])
        : parseInt(props.data["Holding"]);
    let newValue = parseFloat(event.target.value) * holding;
    newValue = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: props.data["portfolioCCY"],
    }).format(newValue);

    props.node.setDataValue("M2M Price*", newValue);
    props.node.setDataValue("Current Valuation", `${newValue}`);
    console.log("M2M Price* updated:", newValue);
    console.log("Current Valuation updated:", `${newValue}`);
  };

  return (
    <input
      type="number"
      ref={inputRef}
      className="bg-slate-500 px-2"
      defaultValue={props.value}
      onBlur={()=>{
        console.log("::::::")
      }}
      onChange={handleInputChange}
      style={{ width: "100%" }}
    />
  );
});

function MarkToMarket() {
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [totalPNL, setTotalPNL] = useState(0);
  const [notification, setNotification] = useState(false);
  const getLiveM2MPosition = useAxios(
    "GET",
    "/finance/live_positions?is_m2m=1"
  );
  const getLivePrices = useAxios(
    "GET",
    "/finance/live_positions?get_live_prices=1&is_m2m=1"
  );
  const setM2M = useAxios("PUT", "/finance/set_m2m_price");
  const getUser = useAxios("GET", "/finance/get_user");
  const handleOpen = () => {
    setOpen(!open);
  };
  const onGridReady = (params) => {
    gridRef.current = params.api;
    params.api.showLoadingOverlay(); // Show the loading overlay
  };
  useEffect(() => {
    getLiveM2MPosition.fetchData();
    getUser.fetchData();
  }, []);

  useEffect(() => {
    console.log("HELLO");
  }, [getLivePrices.responseData]);
  const linkCellRendererSN= (data) => {
    const reqData=JSON.parse(data.data["Security Name"])
    return (
      <a className={reqData.security_urls[0]?.security_name_url?"text-blue-500":""} href={reqData.security_urls[0]?.security_name_url} target="blank">
        {reqData.security.name}
      </a>
    );
  };

  const linkCellRendererST= (data) => {
    const reqData=JSON.parse(data.data["Security Ticker"])
    return (
      <a className={reqData.security_urls[0]?.security_ticker_url?"text-blue-500":""} href={reqData.security_urls[0]?.security_ticker_url} target="blank">
        {reqData.security.ticker}
      </a>
    );
  };

  const linkCellRendererISIN= (data) => {
    const reqData=JSON.parse(data.data["Security ISIN"])
    return (
      <a className={reqData.security_urls[0]?.security_isin_url?"text-blue-500":""} href={reqData.security_urls[0]?.security_isin_url} target="blank">
        {reqData.security.isin}
      </a>
    );
  };

  useEffect(() => {
    console.log("---M2M", getLiveM2MPosition.responseData);
    console.log("---USER", getUser.responseData);
    const optionsDateFormat = {
      year: "numeric",
      month: "short", // options: 'numeric', '2-digit', 'long', 'short', 'narrow'
      day: "numeric",
      weekday: "short", // options: 'long', 'short', 'narrow'
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };
    if (getUser.responseData && getLiveM2MPosition.responseData) {
      let tempPNL = 0;
      const mappedData = getLiveM2MPosition.responseData.live_positions.map(
        (item) => {
          tempPNL += parseFloat(item.net_sum);
          return {
            portfolioCCY: getUser.responseData.portfolio_ccy,
            id: item.id,
            'Security Name': JSON.stringify(item),
            'Security Ticker': JSON.stringify(item),
            'Security ISIN': JSON.stringify(item), 
            "Security Currency": item.security.currency,
            Holding: parseFloat(item.cost_of_current_holdings).toFixed(2),
            "M2M Price Last Upated": new Date(
              item.last_updated_m2m_price
            ).toLocaleDateString("en-US", optionsDateFormat),
            "M2M Price*":
              item.security.currency === "GBP"
                ? (item.m2m_price || "0.00") + " p"
                : item.m2m_price || "0.00",
            "Current Valuation": new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: getUser.responseData.portfolio_ccy,
            }).format(item.current_valuation),
            "Realized P & L": new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: getUser.responseData.portfolio_ccy,
            }).format(item.pnl),
          };
        }
      );
      setTotalPNL(tempPNL);
      setRowData(mappedData);
    }
  }, [getLiveM2MPosition.responseData, getUser.responseData]);
  const  deformatCurrency=(value)=> {
    // Remove any characters that are not digits, minus sign, or period
    const numericValue = value.replace(/[^0-9.-]+/g, "");
    
    // Parse the numeric string to a floating point number or integer
    const number = parseFloat(numericValue);
  
    // If you want to convert to an integer, you can use parseInt instead
    // const number = parseInt(numericValue, 10);
  
    return number;
  }
  const onCellValueChanged = useCallback((params) => {
    console.log(params)
    if (params.colDef.field === "M2M Price*") {
      setM2M.fetchData(
        {},
        {},
        { id: params.data.id, m2m_price: deformatCurrency(params.data[
          "M2M Price*"]) }
      );
    }
  }, []);
  const [colDefs] = useState([
    {
      headerName: "portfolioCCY",
      field: "portfolioCCY",
      unSortIcon: true,
      hide: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "id",
      field: "id",
      unSortIcon: true,
      hide: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Security Name",
      field: "Security Name",
      unSortIcon: true,
      filter: "agTextColumnFilter",
      cellRenderer: linkCellRendererSN,
    },
    {
      headerName: "Security Ticker",
      field: "Security Ticker",
      unSortIcon: true,
      filter: "agTextColumnFilter",
      cellRenderer: linkCellRendererST,
    },
    {
      headerName: "Security ISIN",
      field: "Security ISIN",
      unSortIcon: true,
      filter: "agTextColumnFilter",
      cellRenderer: linkCellRendererISIN,
    },
    {
      headerName: "Security Currency",
      field: "Security Currency",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Holding",
      field: "Holding",
      unSortIcon: true,
      tooltipValueGetter: (params) =>
        params.value ? `Holding: ${params.value}` : null,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "M2M Price Last Upated",
      field: "M2M Price Last Upated",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "M2M Price*",
      field: "M2M Price*",
      unSortIcon: true,
      filter: "agTextColumnFilter",
      tooltipValueGetter: (params) =>
        params.value ? `Holding: ${params.value}` : null,
      editable: true,
      cellEditor: CustomCellEditor,
    },
    {
      headerName: "Current Valuation",
      field: "Current Valuation",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Realized P & L",
      field: "Realized P & L",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
  ]);

  const calculateCurrentValuation = (data) => {
    // Replace with your own logic to calculate current valuation
    return data["Holding"] * data["M2M Price*"];
  };
  colDefs[0].minWidth = 500;
  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitGridWidth",
    };
  }, []);
  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
    };
  }, []);
  const [progress, setProgress] = useState(0);
  const [buttonClicked, setButtonClicked] = useState(false);
  const handleButtonClick = async () => {
    if (!buttonClicked) {
      setOpen(false);
      setButtonClicked(true);
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 10;
          
          if (newProgress >= 100) {
            clearInterval(interval);
            setButtonClicked(false);
            setProgress(0);
          }
          
          return newProgress;
        });
      }, 500);
      await getLivePrices.fetchData();
      await getLiveM2MPosition.fetchData()
    }
  };
  const heightStyle = open ? "70ch" : "68vh";
  return (
    <>
      <div className="flex sm:justify-end justify-center items-center gap-4 mb-5">
        <button
          className={`border-2  ${
            open ? "border-[#0083F2]" : "border-[#3E3F53]"
          } text-white text-xs px-[22px] py-[11px] rounded-md leading-3`}
          onClick={handleOpen}
        >
          View Exchange Rates
        </button>
        <button
          className={`border-2 leading-3 ${
            buttonClicked ? "border-[#0083F2]" : "border-[#3E3F53]"
          } text-white text-xs px-[22px] py-[11px] rounded-md`}
          disabled={buttonClicked}
          onClick={handleButtonClick}
        >
          {buttonClicked ? (
            <div className=" bg-gray-200 rounded-full h-1 dark:bg-gray-700 mt-2 w-[100px]">
              <div
                className="bg-blue-600 h-1 rounded-full"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          ) : (
            "Get Latest Price "
          )}
        </button>
      </div>

      <div className="bg-[#161D27] rounded-lg flex justify-between p-[15px] items-center mb-5">
        <h3 className="text-white text-xs leading-[18px]">
          Total Profit & Loss
        </h3>
        <h2
          className={`text-[28px] ${
            totalPNL >= 0 ? "text-green-500" : "text-red-500"
          } font-semibold leading-[28px]`}
        >
          {getUser.responseData
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: getUser.responseData?.portfolio_ccy,
              }).format(totalPNL)
            : ""}
        </h2>
      </div>
      {open && (
        <>
          <div className="bg-[#161D27] sm:p-[19px] mb-5 rounded-lg ">
            <div className="rounded-lg grid grid-cols-12 sm:gap-9 gap-1">
              {getLiveM2MPosition.responseData?.fx_rates?.map((e) => {
                return (
                  <div className="col-span-12 sm:col-span-6 justify-between flex items-center relative after:absolute after:top-0 after:-right-5 after:w-[2px] after:h-5 after:content-[''] after:bg-[#3E3F53] after:rounded-lg before:absolute before:content-[''] before:bg-[#3E3F53] before:w-full before:h-[2px] before:bottom-0 before:left-0 p-3 sm:p-0 sm:pb-4">
                    <h3 className="uppercase text-xs leading-5 text-white">
                      {e.currency_pair}
                    </h3>
                    <h3 className="text-white text-md leading-5 font-medium">
                      {e.last_tick}
                    </h3>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      <div className="flatposition">
        <div
          className="ag-theme-alpine"
          style={{ height: heightStyle, width: "100%" }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            autoSizeStrategy={autoSizeStrategy}
            ref={gridRef}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            onCellValueChanged={onCellValueChanged}
          />
        </div>
      </div>

      {notification && (
        <>
          <div
            id="toast-danger"
            class="absolute bottom-5 right-8 flex items-center w-full max-w-xs p-[20px] mb-4 text-white bg-[#131720] rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 border-[#3E3F53] border-2"
            role="alert"
          >
            <FaCircleCheck className="text-[#0083F2]" />
            <span class="sr-only">Error icon</span>
            <div class="ms-3 text-sm font-normal text-white">
              Prices Updated Successfully
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default MarkToMarket;
