import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { IoSearch } from "react-icons/io5";
import axios from "axios";
import useAxios from "../../hooks/useAxios";
import { useSelector } from "react-redux";

function MyData() {
  const [selectedTransactionType1, setSelectedTransactionType1] = useState(
    "Transfer from income account"
  );
  const handleTransactionTypeChange1 = (event) => {
    setSelectedTransactionType1(event.target.value);
  };

  const handleTransactionTypeChange2 = (event) => {
    setSelectedTransactionType(event.target.value);
  };
  const params = useSelector((state) => state.position.params);


  
  const responseTransactionType = useAxios(
    "GET",
    "/finance/transaction_types/"
  );
  const gridRef = useRef(null);
  const [rowData, setRowData] = useState([]);
  const [openHide, setOpenHide] = useState(false);
  const [myData, setMyData] = useState(false);
  const [filter, setFilter] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [tradeDate, setTradeDate] = useState("all");
  const [selectedTransactionType, setSelectedTransactionType] = useState(params.transaction_type);
const [applyChangeText, setApplyChangeText] = useState("Apply changes")
  const [selectedSearchedTicker, setSelectedSearchedTicker] = useState("");
  const getMyData = useAxios(
    "GET",
    `/finance/position/filter?transaction-type=${selectedTransactionType}&tradedate=${tradeDate}&security-ticker=${
      selectedSearchedTicker.ticker || ""
    }`
  );
  const handlFilter = () => {
    setFilter(!filter);
  };

  const handleHideOpen = () => {
    setOpenHide(!openHide);
  };
  useEffect(() => {
    if (params.transaction_type){
      applyChange()
    }
  }, [])
  useEffect(() => {
    responseTransactionType.fetchData();
  }, []);

  const [filteredData, setFilteredData] = useState();
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Trade Date",
      field: "trade_date",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Settlement Date",
      field: "settlement_date",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Broker Reference",
      field: "broker_refrence",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Transaction Description",
      field: "transaction_description",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Transaction type",
      field: "transaction_type",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Quantity",
      field: "quantity",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Transaction quantity",
      field: "transaction_quantity",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Security ticker",
      field: "security_ticker",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Security name",
      field: "security_name",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "ISIN",
      field: "isin",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Fx rate",
      field: "fx_rate",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Local ccy",
      field: "local_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Unit cost local ccy",
      field: "unit_cost_local_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Brokerage local ccy",
      field: "brokerage_local_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Transaction tax local ccy",
      field: "transaction_tax_local_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Fx transaction cost local ccy",
      field: "fx_transaction_cost_local_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Total consideration local ccy",
      field: "total_consideration_local_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Foreign ccy",
      field: "foreign_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Unit cost foreign ccy",
      field: "unit_cost_foreign_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Brokerage foreign ccy",
      field: "brokerage_foreign_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Transaction tax foreign ccy",
      field: "transaction_tax_foreign_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Fx transaction cost foreign ccy",
      field: "fx_transaction_cost_foreign_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Total consideration foreign ccy",
      field: "total_consideration_foreign_ccy",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
  ]);

  useEffect(() => {
    const getFilteredData = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_API}/finance/get_securities`,
        {
          params: {
            q: searchQuery,
          },
        }
      );
      setFilteredData(response.data);
    };
    if (searchQuery) {
      getFilteredData();
    }
  }, [searchQuery]);

  const autoSizeStrategy = useMemo(() => {
    return {
      type: "fitGridWidth",
    };
  }, []);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 350,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
    };
  }, []);
  const handleColumnToggle = (columnName) => {
    const newColumnDefs = columnDefs.map((column) => {
      if (column.field === columnName) {
        return { ...column, hide: !column.hide };
      }
      return column;
    });
    setColumnDefs(newColumnDefs);
  };
  const heightStyle =
    filter && openHide ? "42vh" : filter ? "64vh" : openHide ? "55vh" : "77vh";
  const selectSearchedTicker = (item) => {
    setSelectedSearchedTicker({ ...item });
    setSearchQuery("");
  };
  const applyChange = async (notRefresh=false) => {
    setApplyChangeText("Loading......")
    if (!notRefresh){

      setMyData(!myData);
    }
    await getMyData.fetchData();
    setApplyChangeText("Apply changes")
    
  };


  return (
    <>
      {myData ? (
        <>
          <div className="flex lg:justify-end justify-center items-center gap-4 mb-5 flex-wrap lg:flex-nowrap">
            <select
              className=" bg-[#131720]  placeholder:text-xs placeholder:text-[#3E3F53] text-white outline-0 border-2 border-[#3E3F53] text-xs py-[11px] ps-[11px] pe-[74px] leading-[12px] rounded-md"
              onChange={(e)=>{
                setSelectedTransactionType(e.target.value);
              }}
              value={selectedTransactionType}
            >
              {responseTransactionType?.responseData?.results.map((item) => {
                return (
                  <option value={item.transaction_type}>
                    {item.transaction_type}
                  </option>
                );
              })}
            </select>
            <select
              className=" bg-[#131720]  placeholder:text-xs placeholder:text-[#3E3F53] text-white outline-0 border-2 border-[#3E3F53] text-xs py-[11px] ps-[11px] pe-[74px] leading-[12px] rounded-md"
              onChange={(e) => {
                setTradeDate(e.target.value);
              }}
              value={tradeDate}
            >
              <option value="all">All</option>
              <option value="last-week">Last Week</option>
              <option value="last-month">Last Month</option>
              <option value="last-year">Last Year</option>
            </select>
            <button
              className={`border-2  ${
                filter ? "border-[#0083F2]" : "border-[#3E3F53]"
              } text-white text-xs px-[22px] py-[11px] rounded-md leading-[12px]`}
              onClick={handlFilter}
            >
              Filter by security
            </button>
            <button
              className={`border-2  ${
                openHide ? "border-[#0083F2]" : "border-[#3E3F53]"
              } text-white text-xs px-[22px] py-[11px] rounded-md leading-[12px]`}
              onClick={handleHideOpen}
            >
              Hide/Show Column
            </button>

              <button onClick={async ()=>{
                await applyChange(true)
              }}  className="border-2 border-[#0083F2] text-white text-xs px-[22px] py-[11px] rounded-md bg-[#0083F2] leading-[12px]">
                {applyChangeText}
              </button>

          </div>
          {filter && (
            <>
              <div className="bg-[#161D27] p-[15px] mb-[15px]">
                <h2 className="text-white text-md leading-[18px] mb-3 font-medium">
                  Search Ticker (optional)
                </h2>
                <div className="relative">
                  <input
                    type="search"
                    className="bg-[#161D27] rounded-lg w-full py-[11px] outline-none px-14 placeholder:text-sm placeholder:text-[#3E3F53] text-white focus:border focus:border-[#0083F2] focus:border-opacity-50"
                    placeholder="Type to load suggestions - Enter security name, ticker or isin to search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <IoSearch className="absolute text-white top-3 left-4 text-xl " />
                </div>
                {selectedSearchedTicker ? (
                  <>

                  <h2 className="text-white text-md leading-[18px] mt-5 font-medium">
                    Ticker :{" "}
                    <span className="text-blue-400 ml-2">
                      {selectedSearchedTicker.name} |{" "}
                      {selectedSearchedTicker.ticker}{" "}
                    </span>
                  </h2>
                  <h2 className="text-red-400 mt-6" style={{cursor:"pointer"}} onClick={e=>setSelectedSearchedTicker("")}>clear</h2>
                  </>
                ) : null}
                {searchQuery !== "" ? (
                  <ul>
                    {filteredData?.map((item, index) => (
                      <li
                        key={index}
                        className='py-[10px] px-[15px] bg-[#132332] rounded-lg mb-[10px] transition-all duration-[0] relative hover:after:absolute after:bg-[#0083F2] after:top-0 after:left-0 after:content-[""] after:w-[6px] after:h-full after:rounded-tr-lg after:rounded-br-lg'
                        onClick={(e) => selectSearchedTicker(item)}
                      >
                        <h3 className="text-white text-xs leading-[18px] mb-1">
                          {item.name} | {item.symbol} | {item.isin}
                        </h3>
                        <p className="text-xs text-white text-opacity-50 leading-[18px]">
                          Country: {item.country} | Exchange: {item.exchange} |
                          ISIN: {item.isin} | Type: {item.type}
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </>
          )}
          {openHide && (
            <>
              <div className="bg-[#161D27] p-[15px] mb-[15px]">
                <div className="flex flex-wrap items-center gap-4 mt-2">
                  {columnDefs.map((column) => (
                    <div
                      key={column.field}
                      className="flex items-center bg-[#131720] w-fit py-[9px] px-[12px] rounded-full gap-1"
                    >
                      <div
                        className={`rounded-full overflow-hidden ${
                          column.hide ? "border border-[#3E3F53]" : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={!column.hide}
                          onChange={() => handleColumnToggle(column.field)}
                          className="hidden"
                          id={`checkbox-${column.field}`}
                        />
                        <label
                          htmlFor={`checkbox-${column.field}`}
                          className="rounded-full cursor-pointer w-[22px] h-[22px] flex items-center justify-center bg-transparent"
                        >
                          {column.hide ? (
                            <img
                              src="images/right.png"
                              alt="right"
                              className="hidden w-[22px] h-[22px]"
                            />
                          ) : (
                            <img src="images/right.png" alt="right" />
                          )}
                        </label>
                      </div>
                      <label className="text-white text-xs ml-1">
                        {column.headerName}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          <div className="bg-[#161D27]">
            <div className="flatposition mydata">
              <div
                className="ag-theme-alpine"
                style={{ height: heightStyle, width: "100%" }}
              >
                <AgGridReact
                  rowData={getMyData.responseData?.positions?.map((items) => {
                    return {
                      ...items,
                      transaction_tax_foreign_ccy:
                        parseFloat(items.transaction_tax_foreign_ccy) || 0,
                      fx_transaction_cost_foreign_ccy:
                        parseFloat(items.fx_transaction_cost_foreign_ccy) || 0,
                      unit_cost_foreign_ccy:
                        parseFloat(items.unit_cost_foreign_ccy) || 0,
                      brokerage_foreign_ccy:
                        parseFloat(items.brokerage_foreign_ccy) || 0,
                      total_consideration_foreign_ccy:
                        parseFloat(items.total_consideration_foreign_ccy) || 0,
                      brokerage_local_ccy:
                        parseFloat(items.brokerage_local_ccy) || 0,
                      transaction_tax_local_ccy:
                        parseFloat(items.transaction_tax_local_ccy) || 0,
                      fx_transaction_cost_local_ccy:
                        parseFloat(items.fx_transaction_cost_local_ccy) || 0,
                    };
                  })}
                  columnDefs={columnDefs}
                  autoSizeColumns={true}
                  autoSizeStrategy={autoSizeStrategy}
                  ref={gridRef}
                  pagination={true}
                  paginationAutoPageSize={true}
                  paginateChildRows={true}
                  defaultColDef={defaultColDef}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="border-b border-[#3E3F53] p-4 bg-[#161D27] rounded-tl-lg rounded-tr-lg">
            <h2 className="text-white text-lg leading-4">
              Filter below to search your date
            </h2>
          </div>
          <div className="bg-[#161D27] p-[15px] rounded-bl-lg rounded-br-lg">
            <div className="mb-5">
              <div className="pb-[15px]">
                <h2 className="text-white text-md leading-[18px] mb-3 font-medium">
                  Search Ticker (optional)
                </h2>
                <div className="relative">
                  <input
                    type="search"
                    className="bg-[#161D27] rounded-lg w-full py-[11px] outline-none px-14 placeholder:text-sm placeholder:text-[#3E3F53] text-white focus:border focus:border-[#0083F2] focus:border-opacity-50"
                    placeholder="Type to load suggestions - Enter security name, ticker or isin to search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <IoSearch className="absolute text-white top-3 left-4 text-xl " />
                </div>
                {selectedSearchedTicker ? 
                (
                  <>

                  <h2 className="text-white text-md leading-[18px] mt-5 font-medium">
                    Ticker :{" "}
                    <span className="text-blue-400 ml-2">
                      {selectedSearchedTicker.name} |{" "}
                      {selectedSearchedTicker.ticker}{" "}
                    </span>
                  </h2>
                  <h2 className="text-red-400 mt-6" style={{cursor:"pointer"}} onClick={e=>setSelectedSearchedTicker("")}>clear</h2>
                  </>
                ) : null}
                {searchQuery !== "" ? (
                  <ul>
                    {filteredData?.map((item, index) => (
                      <li
                        key={index}
                        className='py-[10px] px-[15px] bg-[#132332] rounded-lg mb-[10px] transition-all duration-[0] relative hover:after:absolute after:bg-[#0083F2] after:top-0 after:left-0 after:content-[""] after:w-[6px] after:h-full after:rounded-tr-lg after:rounded-br-lg'
                        onClick={(e) => selectSearchedTicker(item)}
                      >
                        <h3 className="text-white text-xs leading-[18px] mb-1">
                          {item.name} | {item.symbol} | {item.isin}
                        </h3>
                        <p className="text-xs text-white text-opacity-50 leading-[18px]">
                          Country: {item.country} | Exchange: {item.exchange} |
                          ISIN: {item.isin} | Type: {item.type}
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </div>

            <div className="grid grid-cols-12 gap-5">
              <div className="col-span-12 sm:col-span-6">
                <div className="pb-3">
                  <h3 className="text-xs text-white">Transaction type</h3>
                </div>
                <select
                  className="w-full border border-[#3E3F53] bg-[#131720] p-3 rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white outline-0"
                  onChange={(e) => {
                    setSelectedTransactionType(e.target.value);
                  }}
                  value={selectedTransactionType}
                >
                  <option value="Select Transaction Type">
                    Select Transaction Type
                  </option>
                  {responseTransactionType?.responseData?.results.map(
                    (item) => {
                      return (
                        <option value={item.transaction_type}>
                          {item.transaction_type}
                        </option>
                      );
                    }
                  )}
                </select>
              </div>
              <div className="col-span-12 sm:col-span-6">
                <div className="pb-3">
                  <h3 className="text-xs text-white">Select trade date</h3>
                </div>
                <select
                  className="w-full border border-[#3E3F53] bg-[#131720] p-3 rounded-lg text-xs placeholder:text-xs placeholder:text-[#3E3F53] text-white outline-0"
                  onChange={(e) => {
                    setTradeDate(e.target.value);
                  }}
                  value={tradeDate}
                >
                  <option value="Select trade date">Select trade date</option>
                  <option value="last-week">Last Week</option>
                  <option value="last-month">Last Month</option>
                  <option value="last-year">Last Year</option>
                  <option value="all">All</option>
                </select>
              </div>
            </div>
          </div>

          <div className="text-center mt-5 mb-[15px]">
            <button
              className="bg-[#0083F2] text-white py-[14px] px-[40px] rounded-md"
              onClick={() => {
                applyChange();
              }}
            >
              Apply
            </button>
          </div>
        </>
      )}
    </>
  );
}

export default MyData;
