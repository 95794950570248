import React, { useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community";
import "ag-grid-community/styles/ag-theme-quartz.css";
import useAxios from "../../hooks/useAxios";

function LivePosition() {
  const gridRef = useRef(null);
  const getLivePosition = useAxios("GET", "/finance/live_positions");
  const getUser = useAxios("GET", "/finance/get_user");
  const [rowData, setRowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openHide, setOpenHide] = useState(false);
  const [totalPNL, setTotalPNL] = useState(0);

  const linkCellRendererSN= (data) => {
    const reqData=JSON.parse(data.data["Security Name"])
    return (
      <a className={reqData.security_urls[0]?.security_name_url?"text-blue-500":""} href={reqData.security_urls[0]?.security_name_url} target="blank">
        {reqData.security.name}
      </a>
    );
  };

  const linkCellRendererST= (data) => {
    const reqData=JSON.parse(data.data["Security Ticker"])
    return (
      <a className={reqData.security_urls[0]?.security_ticker_url?"text-blue-500":""} href={reqData.security_urls[0]?.security_ticker_url} target="blank">
        {reqData.security.ticker}
      </a>
    );
  };

  const linkCellRendererISIN= (data) => {
    const reqData=JSON.parse(data.data["Security ISIN"])
    return (
      <a className={reqData.security_urls[0]?.security_isin_url?"text-blue-500":""} href={reqData.security_urls[0]?.security_isin_url} target="blank">
        {reqData.security.isin}
      </a>
    );
  };

  useEffect(() => {
    getLivePosition.fetchData();
    getUser.fetchData();
  }, []);
  useEffect(() => {
    console.log("---M2M", getLivePosition.responseData);
    console.log("---USER", getUser.responseData);
    const optionsDateFormat = {
      year: "numeric",
      month: "short", // options: 'numeric', '2-digit', 'long', 'short', 'narrow'
      day: "numeric",
      weekday: "short", // options: 'long', 'short', 'narrow'
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };
    if (getUser.responseData && getLivePosition.responseData) {
      let tempPNL = 0;
      const mappedData = getLivePosition.responseData.live_positions.map(
        (item) => {
          tempPNL += parseFloat(item.pnl || 0);
          return {
            'Security Name': JSON.stringify(item),
            'Security Ticker': JSON.stringify(item),
            'Security ISIN': JSON.stringify(item), 
            "Security Currency": item.security.currency,
            Holdings: parseFloat(item.cost_of_current_holdings).toFixed(2),
            Timestamp: new Date(item.pulled_price.timestamp).toLocaleDateString(
              "en-US",
              optionsDateFormat
            ),
            "Cost of holdings": parseFloat(
              item[
                `cost_of_current_holdings_${getUser.responseData.accounting_method}`
              ]
            ).toFixed(2),
            Open: item.pulled_price.open,
            High: item.pulled_price.high,
            Low: item.pulled_price.low,
            Volume: item.pulled_price.volume,
            "Previous close": item.pulled_price.previousclose,
            "Last tick": item.pulled_price.last_tick,
            "Change p": item.pulled_price.change_p,
            "Current valuation": new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: getUser.responseData.portfolio_ccy,
            }).format(item.current_valuation),
            "P & L": new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: getUser.responseData.portfolio_ccy,
            }).format(item.pnl),
          };
        }
      );
      setTotalPNL(tempPNL);
      setRowData(mappedData);
    }
  }, [getLivePosition.responseData, getUser.responseData]);
  const handleOpen = () => {
    setOpen(!open);
    setOpenHide(false);
  };
  const handleHideOpen = () => {
    setOpenHide(!openHide);
    setOpen(false);
  };
  const onGridReady = (params) => {
    gridRef.current = params.api;
    params.api.showLoadingOverlay(); // Show the loading overlay
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Security Name",
      field: "Security Name",
      unSortIcon: true,
      filter: "agTextColumnFilter",
      resizable: true,
      cellRenderer: linkCellRendererSN,
    },
    {
      headerName: "Security ticker",
      field: "Security Ticker",
      unSortIcon: true,
      filter: "agTextColumnFilter",
      cellRenderer: linkCellRendererST,
    },
    {
      headerName: "Security ISIN",
      field: "Security ISIN",
      unSortIcon: true,
      filter: "agTextColumnFilter",
      cellRenderer: linkCellRendererISIN,
    },
    {
      headerName: "Security currency",
      field: "Security Currency",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Holdings",
      field: "Holdings",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Timestamp",
      field: "Timestamp",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Cost of holdings",
      field: "Cost of holdings",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Open",
      field: "Open",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "High",
      field: "High",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Low",
      field: "Low",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Volume",
      field: "Volume",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Previous close",
      field: "Previous close",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Last tick",
      field: "Last tick",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Change p",
      field: "Change p",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Current valuation",
      field: "Current valuation",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "P & L",
      field: "P & L",
      unSortIcon: true,
      filter: "agTextColumnFilter",
    },
  ]);
  columnDefs[0].minWidth = 560;

  const defaultColDef = useMemo(() => {
    return {
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      filter: "agTextColumnFilter",
      menuTabs: ["filterMenuTab"],
      resizable: true,
    };
  }, []);

  const handleColumnToggle = (columnName) => {
    const newColumnDefs = columnDefs.map((column) => {
      if (column.field === columnName) {
        return { ...column, hide: !column.hide };
      }
      return column;
    });
    setColumnDefs(newColumnDefs);
  };
  const heightStyle = open ? "55vh" : openHide ? "53vh" : "70vh";

  return (
    <>
      <div className="flex sm:justify-end justify-center items-center gap-4 mb-5">
        <button
          className={`border-2 ${
            open ? "border-[#0083F2]" : "border-[#3E3F53]"
          } text-white text-xs px-[22px] py-[11px] leading-3 rounded-md`}
          onClick={handleOpen}
        >
          View Exchange Rates
        </button>
        <button
          className={`border-2 ${
            openHide ? "border-[#0083F2]" : "border-[#3E3F53]"
          } text-white text-xs px-[22px] py-[11px] leading-3 rounded-md`}
          onClick={handleHideOpen}
        >
          Hide / Show Column
        </button>
      </div>
      <div className="bg-[#161D27] rounded-lg flex justify-between p-[15px] items-center mb-5">
        <h3 className="text-white text-xs leading-[18px]">
          Total Profit & Loss
        </h3>
        <h2
          className={`text-[28px] ${
            totalPNL >= 0 ? "text-green-500" : "text-red-500"
          } font-semibold leading-[28px]`}
        >
          {getUser.responseData
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: getUser.responseData?.portfolio_ccy,
              }).format(totalPNL)
            : ""}
        </h2>
      </div>
      {open && (
        <>
          <div className="bg-[#161D27] sm:p-[19px] mb-5 rounded-lg ">
            <div className="rounded-lg grid grid-cols-12 sm:gap-9 gap-1">
              {getLivePosition.responseData?.fx_rates?.map((e) => {
                return (
                  <div className="col-span-12 sm:col-span-6 justify-between flex items-center relative after:absolute after:top-0 after:-right-5 after:w-[2px] after:h-5 after:content-[''] after:bg-[#3E3F53] after:rounded-lg before:absolute before:content-[''] before:bg-[#3E3F53] before:w-full before:h-[2px] before:bottom-0 before:left-0 p-3 sm:p-0 sm:pb-4">
                    <h3 className="uppercase text-xs leading-5 text-white">
                      {e.currency_pair}
                    </h3>
                    <h3 className="text-white text-md leading-5 font-medium">
                      {e.last_tick}
                    </h3>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
      {openHide && (
        <>
          <div className="bg-[#161D27] p-[15px] mb-5">
            <div className="flex flex-wrap items-center gap-4 mt-2">
              {columnDefs.map((column) => (
                <div
                  key={column.field}
                  className="flex items-center bg-[#131720] w-fit py-[9px] px-[12px] rounded-full gap-1"
                >
                  <div
                    className={`rounded-full overflow-hidden ${
                      column.hide ? "border border-[#3E3F53]" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={!column.hide}
                      onChange={() => handleColumnToggle(column.field)}
                      className="hidden"
                      id={`checkbox-${column.field}`}
                    />
                    <label
                      htmlFor={`checkbox-${column.field}`}
                      className="rounded-full cursor-pointer w-[22px] h-[22px] flex items-center justify-center bg-transparent"
                    >
                      {column.hide ? (
                        <img
                          src="images/right.png"
                          alt="right"
                          className="hidden w-[22px] h-[22px]"
                        />
                      ) : (
                        <img src="images/right.png" alt="right" />
                      )}
                    </label>
                  </div>
                  <label className="text-white text-xs ml-1">
                    {column.headerName}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      <div
        id="myGrid"
        className="ag-theme-alpine ag-theme-quartz"
        style={{ height: heightStyle, width: "100%" }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          ref={gridRef}
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
        />
      </div>
    </>
  );
}

export default LivePosition;
