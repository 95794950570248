import React, { useEffect, useMemo, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { GoDotFill } from "react-icons/go";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import moment from "moment/moment";
import useAxios from "../../hooks/useAxios";

const HtmlContent = ({ htmlString }) => {
  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
};

function UploadPosition() {
  const [selectedFile, setselectedFile] = useState(null);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [uploadedFileDate, setUploadedFileDate] = useState(null);
  const [verified, setVerified] = useState();
  const [invalidData, setInvalidData] = useState([]);
  const [duplicateInfo, setDuplicateInfo] = useState([]);
  const [availableCCY, setAvailableCCY] = useState([]);
  const [transactionType, setTransactionType] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [finalPayload, setFinalPayload] = useState([]);
  const [uploadMessage, setUploadMessage] = useState(
    "No file chosen, select csv file to validate."
  );
  const addPosition = useAxios("POST", "/finance/positions/");
  const [addPositionErr, setAddPositionErr] = useState("");
  const [headers, setHeaders] = useState([]);
  const [duplicateData, setDuplicateData] = React.useState();
  const [duplicate, setDuplicate] = React.useState(false);
  const [securityData, setSecurityData] = React.useState("");
  const responseTransactionType = useAxios(
    "GET",
    "/finance/transaction_types/"
  );
  const getCurrencies = useAxios("GET", "/finance/get_currencies");
  const [showUploadBtn, setShowUploadBtn] = useState(false);

  const handlePopupOpen = async () => {
    setUploadMessage("Uploading...");
    try {
      await addPosition.fetchData({}, {}, finalPayload);
      alert("Uploaded successfully....");
    } catch (error) {
      setErrorMessage(error.response.data);
      setOpen(true);
    }
    setUploadMessage("Done..");
  };

  const handlePopupClose = () => {
    setOpen(false);
    setErrorMessage(null);
    window.location.reload()
  };

  const readCSV = async (e) => {
    const files = e;
    if (files) {
      return new Promise((resolve, reject) => {
        Papa.parse(files, {
          header: true,
          complete(results, files) {
            resolve(results.data);
          },
          error(err, files) {
            reject(err);
          },
        });
      });
    }
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const fileNameParts = file.name.split(".");
    const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();

    if (fileExtension === "csv") {
      setselectedFile(file);
      setUploadedFileName(file.name);
      const csvData = await readCSV(file);
      if (window.Worker) {
        const worker = new Worker(new URL("../../worker.js", import.meta.url));

        // Send data to the worker
        worker.postMessage(csvData); // Example data

        // Receive message from the worker
        worker.onmessage = (e) => {
          console.log("RESULT:", e);
          setUploadMessage("Verifying...");
          if (e.data.errors.length > 0) {
            setUploadMessage("Verifiation failed");
            console.log("ERRORS", e.data.errors);
            setOpen(true);
            const duplicate_list = e.data.errors.map((item) => {
              return {
                ...item.errors[0].row,
                row: item.row,
              };
            });

            setErrorMessage({ duplicate_list: duplicate_list });
          } else {
            setFinalPayload(e.data.payload);
            setUploadMessage("Verifiation success....");
          }

          worker.terminate(); // Terminate the worker after completion
        };

        // Cleanup the worker if component unmounts
        return () => {
          worker.terminate();
        };
      } else {
        console.error("Your browser doesn't support web workers.");
        alert("Your browser doesn't support web workers.");
      }
      setErrorMessage(null);
      console.log(csvData);
      // await formatDataAndValidate(csvData);
      // const currentDate = new Date();
      // const formattedDate = `${currentDate.getFullYear()} ${
      //   currentDate.getMonth() + 1
      // } ${currentDate.getDate()}`;

      // setUploadedFileDate(formattedDate);
    } else {
      setErrorMessage("Invalid file type. Please upload a .csv file.");
      setselectedFile(null);
      setUploadedFileName(null);
      setUploadedFileDate(null);
    }
  };

  return (
    <>
      <div className="grid grid-cols-12 h-[720px] items-center justify-center">
        <div className="col-span-12 xl:col-span-6 relative sm:before:absolute before:content-[''] before:bg-[#3E3F53] before:h-full before:w-[2px] before:top-0 before:-right-0   flex justify-center items-center order-2 xl:order-1 sm:pe-8 sm:ps-5 mt-14 sm:mb-0">
          <div>
            <h3 className="sm:text-base text-white mb-[15px] text-sm">
              Upload file instructions.
            </h3>
            <h2 className=" text-[#0083F2] font-medium sm:text-base text-sm">
              Download our upload template file{" "}
              <Link className="text-white underline">click here</Link> and enter
              your positions.{" "}
            </h2>
            <h2 className=" text-[#0083F2] font-medium sm:text-base text-sm mb-9">
              Examples are provided in the download template.
            </h2>
            <ul>
              <li className="flex text-white sm:text-base text-sm items-start gap-3 mb-[10px] ">
                <span className="w-[25px] px-1 sm:px-0 sm:h-[25px] h-[19px] bg-[#0083F2] flex justify-center items-center rounded-full text-xs font-medium">
                  1
                </span>
                Transaction_type is a mandatory field and must be one of the
                following:
              </li>
              <ul className="mb-[30px]">
                <li className="text-white text-xs flex items-start gap-3 sm:ps-3 ps-2">
                  <span>
                    <GoDotFill className="inline-block text-md text-[#0083F2]" />
                  </span>{" "}
                  <span>
                    {" "}
                    Accounting Transfer, Buy, Closing Balance, Deposit, Dividend
                    – Equity, Dividend – Fund, Dividend - Loyalty Fund, Dividend
                    – Overseas, Fund Rebate, Interest, LOYALTYU, Management
                    Fees, Sell, Transfer from Income Account, Transfer to
                    Capital Account, Other In, Other Out, Unit Rebate
                  </span>
                </li>
              </ul>
              <li className="flex text-white sm:text-base text-sm items-start gap-3 mb-[10px] ">
                <span className="w-[25px] sm:h-[25px] h-[20px] bg-[#0083F2] flex justify-center items-center rounded-full text-xs font-medium ">
                  2
                </span>{" "}
                For all transaction types the following fields are mandatory;
              </li>
              <ul className="mb-[30px]">
                <li className="text-white text-xs flex items-start gap-3 sm:ps-3 ps-2">
                  <span>
                    <GoDotFill className="inline-block text-md text-[#0083F2]" />
                  </span>{" "}
                  <span> trade_date, total_consideration_local_ccy</span>
                </li>
              </ul>
              <li className="flex text-white sm:text-base text-sm items-start gap-3 mb-[10px] ">
                <span className="w-[25px] px-1 sm:px-0 sm:h-[25px] h-[19px] bg-[#0083F2] flex justify-center items-center rounded-full text-xs font-medium">
                  3
                </span>{" "}
                If transaction type is any of the following, then
                security_ticker and unit_cost_local_ccy are mandatory;
              </li>
              <ul className="mb-[30px]">
                <li className="text-white text-xs flex items-start gap-3 sm:ps-3 ps-2">
                  <span>
                    <GoDotFill className="inline-block text-md text-[#0083F2]" />
                  </span>{" "}
                  <span>
                    Buy, Sell, Dividend (Any), Fund Rebate, LOYALTU, Unit Rebate
                  </span>
                </li>
              </ul>
              <li className="flex text-white sm:text-base text-sm items-start gap-3 mb-[10px] ">
                <span className="w-[25px] px-1 sm:px-0 sm:h-[25px] h-[19px] bg-[#0083F2] flex justify-center items-center rounded-full text-xs font-medium">
                  4
                </span>{" "}
                If transaction type is buy or sell then the following fields are
                mandatory;
              </li>
              <ul className="mb-[30px]">
                <li className="text-white text-xs flex items-start gap-3 sm:ps-3 ps-2">
                  <span>
                    <GoDotFill className="inline-block text-md text-[#0083F2]" />
                  </span>{" "}
                  <span>Transaction_quanitiy</span>
                </li>
              </ul>
            </ul>
          </div>
        </div>
        <div className="col-span-12 xl:col-span-6 flex justify-center items-center order-1 xl:order-2 sm:ps-9">
          <div className="flex flex-col items-center justify-center gap-6 pt-10">
            <div className="w-[367px] sm:w-[600px] h-[320px] bg-[#161D27] rounded-2xl flex justify-center items-center">
              <div className="w-[300px] sm:w-[560px] h-[269px] sm:h-[280px] border-dashed border-2 rounded-2xl border-[rgba(0,131,242,0.5)] flex justify-end items-center flex-col">
                <div className="bg-[#131720] p-3 rounded-lg mb-8">
                  {selectedFile ? (
                    <img src="images/csv.png" alt="" />
                  ) : (
                    <img src="images/upload.png" alt="" />
                  )}
                </div>
                <h5 className="text-white text-lg mb-5">{uploadMessage}</h5>
              </div>
            </div>
            <label className="cursor-pointer relative">
              {selectedFile ? (
                <button
                  className="px-[40px] py-[14px] bg-[#0083F2] rounded-md text-white leading-4"
                  onClick={handlePopupOpen}
                >
                  Upload file
                </button>
              ) : (
                <>
                  <input
                    type="file"
                    className="absolute top-0 left-0 opacity-0 h-full w-full cursor-pointer"
                    fileExtension="csv"
                    onChange={handleFileChange}
                  />
                  <button className="px-[40px] py-[14px] bg-[#3E3F53] rounded-md text-white leading-4 text-base">
                    Upload file for verification
                  </button>
                </>
              )}
            </label>
          </div>
        </div>
      </div>
      {open && (
        <div className="fixed bg-slate-800 inset-0 bg-opacity-70 z-50 flex items-center justify-center p-5 pt-32">
          <div className="border border-[#DD2929] rounded-md w-full h-full bg-[#132332] overflow-scroll">
            <div
              dtyle={{ width: "100%" }}
              className="bg-red-600 bg-opacity-10 p-[20px] flex justify-between items-center"
            >
              <img src="images/alert.png" alt="" />
              <h3 className="text-[#DD2929] text-[22px] font-medium">
                Error (
                {errorMessage?.message || "Check these rows for the error"})
              </h3>

              <span
                className="p-[8px] rounded-lg bg-[#132332] flex justify-center items-center cursor-pointer"
                onClick={handlePopupClose}
              >
                <IoClose className="text-lg text-[#0083f2]" />{" "}
              </span>
            </div>
            <div>
              <table className="text-white">
                <thead>
                  <tr>
                    <th className="px-4 py-2 w-64">{errorMessage?.duplicate_list[0]?.row?"ROW_NO":"S_NO"}</th>
                    <th className="px-4 py-2 w-64"> TRADE_DATE</th>
                    <th className="px-4 py-2 w-64"> SETTLEMENT_DATE</th>
                    <th className="px-4 py-2 w-64">BROKER_REFERENCE</th>
                    <th className="px-4 py-2 w-64">TRANSACTION_DESCRIPTION</th>
                    <th className="px-4 py-2 w-64"> UNIT_COST_LOCAL_CCY</th>
                    <th className="px-4 py-2 w-64">QUANTITY</th>
                    <th className="px-4 py-2 w-64">BROKERAGE_LOCAL_CCY</th>
                    <th className="px-4 py-2 w-64">
                      TRANSACTION_TAX_LOCAL_CCY
                    </th>
                    <th className="px-4 py-2 w-64">
                      FX_TRANSACTION_COST_LOCAL_CCY
                    </th>
                    <th className="px-4 py-2 w-64">
                      TOTAL_CONSIDERATION_LOCAL_CCY
                    </th>
                    <th className="px-4 py-2 w-64">TRIM_CODE</th>
                    <th className="px-4 py-2 w-64">TRANSACTION_QUANTITY</th>
                    <th className="px-4 py-2 w-64">TRANSACTION_TYPE</th>
                    <th className="px-4 py-2 w-64">SECURITY_NAME</th>
                    <th className="px-4 py-2 w-64">SECURITY_TICKER</th>
                    <th className="px-4 py-2 w-64">ISIN</th>
                    <th className="px-4 py-2 w-64">UNIT_COST_FOREIGN_CCY</th>
                    <th className="px-4 py-2 w-64">BROKERAGE_FOREIGN_CCY</th>
                    <th className="px-4 py-2 w-64">
                      TRANSACTION_TAX_FOREIGN_CCY
                    </th>
                    <th className="px-4 py-2 w-64">
                      FX_TRANSACTION_COST_FOREIGN_CCY
                    </th>
                    <th className="px-4 py-2 w-64">
                      TOTAL_CONSIDERATION_FOREIGN_CCY
                    </th>
                    <th className="px-4 py-2 w-64"> FXRATE_FLOAT</th>
                    <th className="px-4 py-2 w-64">LOCAL_CCY</th>
                    <th className="px-4 py-2 w-64">FOREIGN_CCY</th>
                  </tr>
                </thead>
                <tbody>
                  {errorMessage?.duplicate_list.map((row, index1) => {
                    return (
                      <>
                        <tr key={index1}>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-red text-left"
                          >
                            <HtmlContent
                              htmlString={row?.row || (index1 + 1).toString()}
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent htmlString={row?.trade_date || ""} />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={row?.settlement_date || ""}
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={row?.broker_reference || ""}
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={row?.transaction_description || ""}
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={row?.unit_cost_local_ccy || ""}
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent htmlString={row?.quantity || ""} />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={row?.brokerage_local_ccy || ""}
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={row?.transaction_tax_local_ccy || ""}
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={
                                row?.fx_transaction_cost_local_ccy || ""
                              }
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={
                                row?.total_consideration_local_ccy || ""
                              }
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent htmlString={row?.trim_code || ""} />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={row?.transaction_quantity || ""}
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={row?.transaction_type || ""}
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          ></td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent htmlString={row?.security || ""} />
                            <br />
                            {securityData && (
                              <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
                                {securityData}
                              </span>
                            )}
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          ></td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={row?.unit_cost_foreign_ccy || ""}
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={row?.brokerage_foreign_ccy || ""}
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={
                                row?.transaction_tax_foreign_ccy || ""
                              }
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={
                                row?.total_consideration_foreign_ccy || ""
                              }
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent
                              htmlString={
                                row?.total_consideration_foreign_ccy || ""
                              }
                            />
                          </td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          ></td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          ></td>
                          <td
                            scope="row"
                            style={{ width: "250px", padding: "0 20px" }}
                            className="text-danger text-left"
                          >
                            <HtmlContent htmlString={row?.foreign_ccy || ""} />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UploadPosition;
